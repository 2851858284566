import { isDefined } from '~/utils/guards/isDefined';
import { useHostname } from '~/composables/useHostname';

export function useGlobalMetadata() {
  const slug = useSlug();
  const locale = useLocale();
  const { $globalPageSettings } = useNuxtApp();

  // XXX: access to useRuntimeConfig inside buildUrlString results
  // in a "can not use useNuxtApp" error...
  const serverUrlOrigin = useHostname();
  const url = computed(() =>
    buildUrlString(
      locale.value,
      slug.value,
      undefined,
      undefined,
      serverUrlOrigin
    )
  );

  useHead({
    script: [
      {
        type: 'application/ld+json',
        innerHTML: JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'Organization',
          name: $globalPageSettings.value?.companyName ?? '',
          url: url.value,
          logo: $globalPageSettings.value?.companyLogo?.url ?? undefined,
          address: {
            '@type': 'PostalAddress',
            streetAddress:
              $globalPageSettings.value?.companyStreet ?? undefined,
            postalCode:
              $globalPageSettings.value?.companyPostalCode ?? undefined,
            addressLocality:
              $globalPageSettings.value?.companyCity ?? undefined,
            addressRegion:
              $globalPageSettings.value?.companyRegion ?? undefined,
            addressCountry:
              locale.value?.split('-').at(-1)?.toUpperCase() ?? undefined
          },
          sameAs:
            $globalPageSettings.value?.companySocialMediaLinksCollection?.items
              ?.map((link) => link?.externalUrl)
              .filter(isDefined)
        }),
        tagPriority: -1 // Wird als letztes eingefügt
      }
    ]
  });

  // the lang attribute should follow RFC 5646
  // @see https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/lang
  const htmlLang = computed(() => {
    const [lang, country] = locale.value?.split('-') ?? [];
    if (!lang) {
      return;
    }
    if (!country) {
      return lang.toLowerCase();
    }

    return `${lang.toLowerCase()}-${country.toUpperCase()}`;
  });

  // every page should have a lang attribute. this might be overwritten
  // by "useMetadata" with a local from a page/metadata.
  useHead({
    htmlAttrs: {
      lang: () => htmlLang.value
    }
  });
}
